import React from 'react'
import {withPageAuthRequired} from '@auth0/nextjs-auth0'

/**
 * Helper to check if authentication is required to access the page
 */
export const authWrapper = (component: React.FC) => {
  const urlParams =
    typeof window !== 'undefined' && new URLSearchParams(window.location.search)

  if (urlParams && urlParams.get('auth') === '1') {
    return withPageAuthRequired(component)
  }

  return component
}
