import {useUser} from '@auth0/nextjs-auth0'
import axios from 'axios'
import {useEffect} from 'react'
import useSWR from 'swr'
import {setUser} from '../features/user/user-slice'
import {ApiAgentResponse} from '../types'
import {useAppDispatch} from './useAppDispatch'

type UserMetaData = {
  channelId: number
  companyId: number
  bcId: string
  referralCode: string
}

export function useSyncAgentWithAuth0() {
  const dispatch = useAppDispatch()
  const {user, isLoading} = useUser()

  // if user is authed then try the agent
  const {data, error} = useSWR<ApiAgentResponse>(
    `/api/agents/${user?.sub}`,
    (url) => user && fetch(url).then((res) => res.json()),
  )

  useEffect(() => {
    if (!isLoading && data) {
      const metaData = user.user_metadata as UserMetaData
      if (!data.agent) {
        axios
          .post<ApiAgentResponse>('/api/agents/createAgent', {
            userId: user.sub,
            channelId: metaData.channelId,
            companyId: metaData.companyId,
            agentEmailAddress: user.email,
            agentName: user.name,
            bcId: metaData?.bcId,
            referralCode: metaData?.referralCode,
          })
          .then((a) => dispatch(setUser(a.data.agent)))
          .catch((e) => console.error('Error updating agent', e))
      } else {
        dispatch(setUser(data.agent))
      }
    }

    if (error) console.error('Error fetching agent', error)
  }, [data, isLoading, error])
}
